import React from "react"
import { MetaTags } from "react-meta-tags"
import PropTypes from "prop-types"
import { useSelector } from "react-redux"
import { LogoSm } from "assets/imgs/logos"
import { FRONT_END_URL } from "helpers/api_methods"

const MetaData = ({
  title,
  content,
  keyword,
  description,
  canonicalLink,
  ogImage,
  metaProduct,
}) => {
  const { company, currency } = useSelector(state => ({
    company: state?.SiteSettings.siteSettings?.company,
    currency: state?.SiteSettings.siteSettings?.company?.currency,
  }))

  return (
    <MetaTags>
      <title>{`${title || "Home"} | ${"Chocochi"} `}</title>

      <link rel="canonical" href={canonicalLink ? canonicalLink : "/"} />
      <link rel="icon" href={LogoSm} />

      <meta property="og:title" content={content} />
      <meta property="og:description" content={description} />
      <meta name="description" content={description} />
      <meta property="og:type" content="ecommerce" />
      <meta
        property="og:url"
        content={`${FRONT_END_URL}${canonicalLink && canonicalLink}`}
      />
      <meta property="og:image" content={ogImage ? ogImage : LogoSm} />
      {/* <meta name="keywords" content={keyword}></meta> */}
      <meta
        property="product:availability"
        content={metaProduct?.availability}
      />
      <meta property="product:price:amount" content={metaProduct?.amount} />
      <meta
        property="product:price:currency"
        content={currency ? currency : "INR"}
      />
      <meta
        property="product:retailer_item_id"
        content={metaProduct?.itemCode}
      />
      <meta property="product:category" content={metaProduct?.category} />
    </MetaTags>
  )
}

export default MetaData

MetaData.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string,
  keyword: PropTypes.any,
  description: PropTypes.string,
  canonicalLink: PropTypes.string,
  ogImage: PropTypes.string,
  metaProduct: PropTypes.object,
}
